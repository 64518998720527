.carouselContainer {
  padding-top: 200px;
  padding-bottom: 200px;
}

.carouselSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  width: 90vw !important;
}

.carouselTextContainer {
  padding: 50px;
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow:  20px 20px 60px #bebebe,
              -20px -20px 60px #ffffff;
}

/* .carousel-control-next-icon {
  background-color: red;
  border-radius: 50px;
} */

/* Media Breakpoints */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

@media (max-width: 575.98px) {  }