.ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23a2d9ff" fill-opacity="1" d="M0,192L34.3,186.7C68.6,181,137,171,206,154.7C274.3,139,343,117,411,101.3C480,85,549,75,617,69.3C685.7,64,754,64,823,101.3C891.4,139,960,213,1029,208C1097.1,203,1166,117,1234,96C1302.9,75,1371,117,1406,138.7L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>');
  background-size: 300% 100%;     
  background-size: cover;
  background-position: center center;



}

/* Media Breakpoints */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .ctaContainer {
    /* background-size: 200% 100%;    */
  }
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {  }

@media (max-width: 575.98px) {  }