

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.card {
  width: 900px;
  /* max-width: 50%; */
  height: 300px;
  background: var(--primary200);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  transition: 0.5s ease-in-out;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
}


.card:hover > .img {
  transition: 0.5s ease-in-out;
  background: linear-gradient(#9198e5, #712020);
}

.textContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.span {
  font-size: 10px;
}

.h1 {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.p {
  font-size: 26px;
  font-weight: lighter;
  text-align: center;
}


.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: var(--primary400);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid var(--primary100);
  outline: none;
  overflow: hidden;
  font-size: 20px;
  width: 300px;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  transform: scale(1.05);
  border-color: var(--primary300);
}

.button:hover .icon {
  transform: translate(4px);
}

.button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

/* Media Breakpoints */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .card {
    width: 90%
  }
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .h1 {
    font-size: 32px;
  }
  
  .p {
    font-size: 24px;
  }
 }

@media (max-width: 575.98px) { 
  .h1 {
    font-size: 28px;
  }
  
  .p {
    font-size: 18px;
  }

  .button {
    width: 250px;
  }
 }
