* {
    margin: 0;
    box-sizing: border-box;
    font-family: "VisionSubsea";
}

@font-face {
    font-family:'VisionSubsea' ;
    src: url("../fonts/ArtyType\ -\ Omni\ Bold.ttf") format('woff2');
}

.landingpage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    transition: 0.5s;
    font-family: "VisionSubsea";
}

.video-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.bg-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    top: 0;
    z-index: 0;
}

.home-text {
    width: 70%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 2;
    color: white;
}

.home-text h1 {
    font-size: 6em;
    animation-name: fade;
    animation-duration: 4s;
}

.home-text p {
    justify-self: flex-end;
    font-size: 2em;
    text-shadow:
        0.07em 0 black,
        0 0.07em black,
        -0.07em 0 black,
        0 -0.07em black;
  
}

.home-btn {
    width: 6em;
    height: 2em;
    border: 2px solid white;
    border-radius: 0.25em;
    background-color: rgba(255, 255, 255, 0.457);
    z-index: 1;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card-title, .card-text {
    color: black;
}

.carouselSection {
    width: 100%;
    height: 500px;
}

.innerContainer {
    width: 50%;
    margin-top: 100px;
    margin-bottom: 100px;
}

.heroLogo {
  width: 50vw;
}

.sectionContainer {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23a2d9ff" fill-opacity="1" d="M0,32L34.3,37.3C68.6,43,137,53,206,90.7C274.3,128,343,192,411,186.7C480,181,549,107,617,96C685.7,85,754,139,823,181.3C891.4,224,960,256,1029,240C1097.1,224,1166,160,1234,144C1302.9,128,1371,160,1406,176L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>');
  background-size: 300% 100%;     
  background-size: cover;
  background-position: center center;
}

/* ---------- Animation --------------- */
@keyframes fade {
    0% {
        opacity: 0;
        margin-left: 3em;
    }
    100% {
        opacity: 1;
    }
}

/* Media Breakpoints */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .home-text p {
    justify-self: flex-end;
    font-size: 1em;
  }

  .heroLogo {
    width: 80vw;
  }

 }

@media (max-width: 575.98px) {  }