
.aboutContainer {
  /* height: 100%; */

}
.imageContainer {
 width: 100%;
 height: 100vh;
 background-image: url("../media/about_2.jpg");
 background-size: cover;
 display: flex;
 justify-content: flex-end;
 align-items: center;
}
/* 
.aboutImage {
  width: 100%;
  height: 'auto';
  position: absolute;
  overflow-y: scroll;
} */


.aboutCard {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 900px;
  /* height: 600px; */
  border: 2px solid white;
  border-radius: 16px;
  padding: 15px;
  margin: 5%;
  background-color: white;
  opacity: 0.8;
}

.aboutText {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Media Breakpoints */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

  .aboutText {
    font-size: 24px;
  }
 }

/* // Small devices (landscape phones, less than 768px) */
 @media (max-width: 767.98px) { 
  .aboutText {
    font-size: 24px;
  }
  .imageContainer {
    background-position: right center;
  }
 }

 @media (max-width: 575.98px) { 
  .aboutCard {
    /* margin-top: 100px;
    margin-bottom: 100px; */
  }
  .aboutText {
    font-size: 16px;
  }
 }