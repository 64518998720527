.page-section {
  margin-top: 150px;
  margin-bottom: 150px;
}

.container-fluid {
  text-align: center;
}

.containerText {
  font-size: 80px;
}

/* Media Breakpoints */

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .containerText {
    font-size: 64px;
  }
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .containerText {
    font-size: 48px;
  }
 }

@media (max-width: 575.98px) { 
  .containerText {
    font-size: 32px;
  }
 }